import React from 'react';

import { Section, Text } from '@utils';
import SEO from '@component/SEO';

const Privacy: React.FC = () => {
  return (
    <>
      <SEO
        path="/privacy"
        title="Privacy Policy"
        description="At Coral Dev, we value your privacy and rights to withhold information. Please read our privacy policy to see how and when we use your data."
      />
      <Section id="privacy">
        <div className="lg:max-w-5xl lg:mx-auto space-y-10">
          <Text style="h2" tag="h1">
            Privacy Policy
          </Text>
          <Text>
            We are committed to the privacy and confidentiality of information
            provided to us. This Privacy Statement provides information to help
            you understand how we collect, use and share your personal
            information and our privacy practices about any personal information
            collected by us from you directly and through the Platforms or the
            use of our products and services.
          </Text>
          <Text style="h3" tag="h2">
            1. Consent and removal.{' '}
          </Text>
          <Text>
            We process your data if you have consented to the processing
            activity. You may revoke your consent at any time. Doing so will bar
            us from further processing of your data based on your consent but
            will not impact the lawfulness of processing based on your consent
            before it was withdrawn.
          </Text>
          <Text style="h3" tag="h2">
            2. Information we collect.{' '}
          </Text>
          <Text>
            This is information about you that you give us directly when you
            interact with us. You may provide it to us by filling out a form on
            our Site, corresponding with us by phone, e-mail, or at an in-person
            event. Depending on how you interact with us, the personal
            information we collect may include but without limitation:
          </Text>
          <ul className=" pl-4 list-disc">
            <li>
              <Text>Browser usage</Text>
            </li>
            <li>
              <Text>Browser type</Text>
            </li>
            <li>
              <Text>Personal information where entered</Text>
            </li>
            <li>
              <Text>
                IP Address or any information relating to our website.
              </Text>
            </li>
          </ul>
          <Text style="h3" tag="h2">
            3. Using your information
          </Text>
          <Text>In short.</Text>
          <Text>
            (a) To personalize your experience (your information helps us to
            better respond to your individual needs)
          </Text>
          <Text>
            (b) To improve our website (we continually strive to improve our
            website offerings based on the information and feedback we receive
            from you)
          </Text>
          <Text>
            To improve customer service (your information helps us to respond to
            your customer service requests, and support needs more effectively)
          </Text>
          <Text>
            To process transactions, Your information, whether public or
            private, will not be sold, exchanged, transferred, or given to any
            other company for any reason whatsoever, without your consent, other
            than for the express purpose of delivering the purchased product or
            service requested.
          </Text>
        </div>
      </Section>
    </>
  );
};

export default Privacy;
